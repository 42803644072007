import { useEffect } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import WebIcon from '@material-ui/icons/Web';
import Label from 'src/components/Label';
import { useListPcsEventsQuery } from 'src/hooks/eventHooks';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { mapPcsEventStatus } from 'src/utils/format';
import { useGetRepMe } from 'src/hooks/repHooks';

import { PcsStatus } from 'src/types';

const labelColors = {
  complete: 'success',
  pending: 'warning',
  rejected: 'error',
};

const useStyles = makeStyles(() => ({
  root: {},
}));

const EventList = ({ className, ...rest }) => {
  const classes = useStyles();
  const eventsQuery = useListPcsEventsQuery(
    {
      limit: 50,
      lookback: 3600 * 24 * 5,
    },
    5000
  );
  const events = eventsQuery.data;
  const filteredEvents = events?.filter(
    (event) =>
      event.pcs_status !== PcsStatus.pcs_handled &&
      event.pcs_status !== PcsStatus.operator_handled &&
      event?.ai_processing_completed
  );

  const { data: repData } = useGetRepMe();

  const getTimeDifference = (eventTimestamp) => {
    const now = new Date().getTime(); // Current time in milliseconds
    const eventTime = eventTimestamp * 1000; // Convert Unix timestamp to milliseconds

    const diffInMs = now - eventTime;

    // Check if the event has already occurred
    if (diffInMs < 0) {
      return 'Event is in the future';
    }

    // Calculate total seconds from milliseconds
    const totalSeconds = Math.floor(diffInMs / 1000);

    // Calculate minutes and remaining seconds
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    // Format seconds to always have two digits
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${minutes}:${formattedSeconds}`;
  };

  if (!eventsQuery.isSuccess) return <LoadingSpinner />;

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                {repData?.role === 'admin' && (
                  <TableCell sortDirection="desc">
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel active direction="desc">
                        Event ID
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                )}
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Location (sid)</TableCell>
                <TableCell>Specialist Handling</TableCell>
                <TableCell>Alarm State</TableCell>
                <TableCell>Objects</TableCell>
                <TableCell>Movement</TableCell>
                <TableCell>Faces</TableCell>
                <TableCell>Event Age</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEvents?.map((event) => (
                <TableRow hover key={event.eventId}>
                  {repData?.role === 'admin' && (
                    <TableCell>
                      <Link
                        to={`/app/verify-respond?userId=${event.userId}&sid=${event.sid}&eventId=${event.eventId}&uuid=${event.uuid}`}
                      >
                        <IconButton aria-label="verify-respond">
                          <WebIcon />
                        </IconButton>
                      </Link>
                      {event.eventId}
                    </TableCell>
                  )}
                  <TableCell>{event.info}</TableCell>
                  <TableCell>
                    <Label color={labelColors[event.pcs_status]}>
                      {mapPcsEventStatus(event)}
                    </Label>
                  </TableCell>
                  <TableCell>{event.sid}</TableCell>
                  <TableCell>{event.handler?.email}</TableCell>
                  <TableCell>{event.alarmState}</TableCell>
                  <TableCell>{event.tracks?.length}</TableCell>
                  <TableCell>{event.moves?.length}</TableCell>
                  <TableCell>{event.faces?.length}</TableCell>
                  <TableCell align="right">
                    {getTimeDifference(event.eventTimestamp)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box p={2} display="flex" justifyContent="flex-end">
        <Button
          component={RouterLink}
          size="small"
          to="/app/event-list"
          endIcon={<NavigateNextIcon />}
        >
          See all
        </Button>
      </Box>
    </Card>
  );
};

EventList.propTypes = {
  className: PropTypes.string,
};

export default EventList;
