/** Data Models */
/**
 * PCS Data Models (match to models.py)
 * */
// # USER Models
type Subscriber = {
  user_id: number;
  email: string;
  access_token: string; // SS Auth access_token (not PCS)
  expires: number; // SS Auth access_token expires timestamp
  expires_in?: number; // Calculated expires_in

  client_id?: string;
  device_id?: string;
  auth_fail_count?: number; // Count of times SS Auth refresh has failed
  auth_fail_last?: number; // Timestamp when SS Auth refresh last failed
  refresh_token?: string; // SS Auth refresh_token (not PCS)
};

// # CAMERA models
export type MotionZone = {
  pixelSize: {
    w: number;
    h: number;
  };
  regions: {
    cls: string; // = 'Exclude'
    color: string; //  = '#f44336'
    points: number[][];
    type: string; // = 'polygon'
    id: string; // = 'id'
  }[];
};

export type PcsCamera = {
  uuid: string;
  user_id: number;
  sid: number;
  monitored: boolean;
  motionZone?: MotionZone[];
};

// # SERVICE models
export enum ServiceFeatureId {
  night_8_6 = 'night_8_6',
  day_6_8 = 'day_6_8',
  away_monitored = 'away_monitored',
  home_monitored = 'home_monitored',
  off_monitored = 'off_monitored',
}

export type ServiceFeature = {
  value: boolean;
};

export type Service = {
  sid: number;
  user_id: number;
  features: Record<ServiceFeatureId, ServiceFeature>;
  // serviceMap: Dict = service_map
};

// # OBJECT DETECTION models
export type BoundingBox = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export enum DetectionFlag {
  rejected = 'rejected',
  important = 'important',
}

export enum DetectionCategory {
  tracks = 'tracks',
  moves = 'moves',
  faces = 'faces',
}

export type DetectionBase = {
  id: string;
  type: string; // = Field(..., description="Type of detector used (ie, Yolov4)")
  rejected?: boolean; // Manually reviewed and rejected
  important?: boolean; // Manually reviewed and found important
  pts_seconds: number; // Video timestamp where this detection was found; not required because added after detection and causes validation failure if required",
  img_file?: string; // = Field(None, description="Path to image file")
  bbox: BoundingBox;

  category?: 'tracks' | 'faces' | 'moves';
};

export type TrackedObject = DetectionBase & {
  object_id: number; //= Field(None, description="Ascending integer id of object (person 1 in this video)")
  class_name: string;
  max_confidence: number;
  current_confidence: number;
  category?: 'tracks';
};

export type MotionArea = DetectionBase & {
  type: 'MOG'; // only MOG currently
  category?: 'moves';
};

export type FaceLandmarks = {
  left_eye?: number[]; // [x,y]
  right_eye?: number[];
  nose?: number[];
  left_lip?: number[];
  right_lip?: number[];
};

export type Face = DetectionBase & {
  type: 'retinaface-facenet'; // = Field(..., example="retinaface-facenet", description="[Face Detector]-[Face Representor]")
  face_confidence?: number; // = None
  dominant_emotion?: string; // = None
  emotion_score?: number; // = None
  embedding?: number[]; //] = None
  group_id?: string; // = Field(None, description="Matched face in this cluster (event)")
  group_distance?: number; // = None
  match_person_id?: string; // = Field(None, description="Matched person in people database")
  match_face_id?: string; // = Field(None, description="Matched face of person in people database")
  match_distance?: number; // = None
  confirmed?: boolean; // = Field(None, description="Manually reviewed and confirmed")
  landmarks?: FaceLandmarks; //= None
  // face_img: Any = None  # Used for moving Face around, not storing to DB
  category?: 'faces';
};

// # PERSON MODELS
export enum PersonCategory {
  friendly = 'friendly',
  neutral = 'neutral',
  unknown = 'unknown',
  banned = 'banned',
}

export type Person = {
  person_id?: string;
  user_id?: number;
  sid?: number;
  name: string;
  category: PersonCategory;
  primary_face_id?: string;
  allowed?: boolean;
  faces?: Face[];
};

// DISPOSITION
export enum Action {
  canceled_rep = 'canceled_rep',
  canceled_ai = 'canceled_ai',
  canceled_alarm_state = 'canceled_alarm_state',
  canceled_user_app = 'canceled_user_app',
  canceled_safe_word = 'canceled_safe_word',
  dispatched_police = 'dispatched_police',
  dispatched_fire = 'dispatched_fire',
  dispatched_medical = 'dispatched_medical',
  canceled_by_customer = 'canceled_by_customer',
  paused_monitoring = 'paused_monitoring',
}
export type Disposition = {
  action?: Action;
  threat_level?: number; // NOTE: this threat_level is different than the ThreatLevel set by PCS
};

// EVENT models
export enum PcsStatus {
  not_monitored = -50,
  canceled = -40,
  operator_handled = -30,
  pcs_handled = -20,
  expired = -10,
  new = 0,
  watch = 10,
  verify = 20,
  dispatch = 30,
  follow_up = 40,
  hold = 50,
}

export enum ThreatLevel {
  none = 0,
  motion = 10,
  person = 20,
  multiple = 30,
}

export type PcsEvent = {
  eventId: number;
  eventTimestamp: number;
  userId: number;
  sid: number;
  pcs_status?: PcsStatus;
  threat_level?: ThreatLevel;
  alarmState?: string;
  account?: string;
  eventType?: string;
  info?: string;
  video?: Record<string, unknown>;
  uuid?: string;
  handler?: Record<string, unknown>;
  video_path?: string;
  tracks?: [];
  moves?: [];
  faces?: Record<string, unknown>[];
  disposition?: Disposition;
  people?: Person[];
  locationOffset: number;
};

export type NotificationSettings = {
  user_id: number;
  sid: number;
  push: {
    common_events: {
      all: boolean;
    };
    person_events: {
      all: boolean;
    };
  };
  email: {
    common_events: {
      all: boolean;
    };
    person_events: {
      all: boolean;
    };
  };
};

export enum NotificationCategory {
  common_events = 'common_events',
  person_events = 'person_events',
  emergency_events = 'emergency_events',
}

export type PushNotification = {
  user_id: number;
  message: string;
  title: string;
  event_id: number;
  critical?: boolean;
  category?: NotificationCategory; // By default it will be common_event on the server side
};

export type MailNotification = {
  uid: string;
  subject: string;
  html: string;
  text: string;
  eventId: number;
  critical?: boolean;
  category?: NotificationCategory; // By default it will be common_event on the server side
};

/**
 * SS Data Models
 */
export enum SsCameraConnectionStatus {
  Unknown = 'unknown',
  Offline = 'offline',
  Online = 'online',
}

export type SsCamera = {
  staleSettingsTypes: [];
  upgradeWhitelisted: true;
  model: 'SS001' | 'SS002';
  sid: 76246;
  uuid: '29322af849fb4eb22866c8b5400005af';
  cameraSettings: {
    spotlight: {
      enableColorNightMode: false;
    };
    videoFlip: {
      enable: false;
      verticalFlip: false;
      horizontalFlip: false;
    };
    alarmState: {
      recordOnAlarm: false;
      recordOnCount: false;
      spotlightFlashOnAlarm: false;
      sirenOnAlarm: false;
    };
    cameraName: 'Kitchen';
    pictureQuality: '720p';
    nightVision: 'auto';
    statusLight: 'off';
    micSensitivity: 50;
    micEnable: true;
    speakerVolume: 75;
    motionSensitivity: 0;
    shutterHome: 'closedAlarmOnly';
    shutterAway: 'open';
    shutterOff: 'closedAlarmOnly';
    wifiSsid: 'chlecb';
    canStream: false;
    canRecord: false;
    pirEnable: true;
    vaEnable: true;
    notificationsEnable: false;
    enableDoorbellNotification: false;
    doorbellChimeVolume: 'off';
    privacyEnable: false;
    hdr: false;
    vaZoningEnable: false;
    vaZoningRows: 0;
    vaZoningCols: 0;
    vaZoningMask: [];
    maxDigitalZoom: 10;
    supportedResolutions: ['480p', '720p'];
    admin: {
      tinyYolo: {
        numFramesToAnalyze: 5;
        confidenceThreshold: 0.9;
        positiveFramesThreshold: 0.5;
      };
      powerControl: {
        disabled: false;
      };
      IRLED: 0;
      pirSens: 0;
      statusLEDState: 1;
      lux: 'lowLux';
      motionDetectionEnabled: false;
      motionThresholdZero: 0;
      motionThresholdOne: 10000;
      levelChangeDelayZero: 30;
      levelChangeDelayOne: 10;
      audioDetectionEnabled: false;
      audioChannelNum: 2;
      audioSampleRate: 16000;
      audioChunkBytes: 2048;
      audioSampleFormat: 3;
      audioSensitivity: 50;
      audioThreshold: 50;
      audioDirection: 0;
      bitRate: 284;
      longPress: 2000;
      kframe: 1;
      gopLength: 40;
      idr: 1;
      fps: 20;
      firmwareVersion: '2.5.2.76';
      netConfigVersion: string;
      camAgentVersion: string;
      lastLogin: 1623876890;
      lastLogout: 1623876889;
      pirSampleRateMs: 500;
      pirHysteresisHigh: 1;
      pirHysteresisLow: 10;
      pirFilterCoefficient: 1;
      logEnabled: true;
      logLevel: 3;
      logQDepth: 20;
      firmwareGroup: 'public';
      irOpenThreshold: 100;
      irCloseThreshold: 250;
      irOpenDelay: 3;
      irCloseDelay: 3;
      irThreshold1x: 100;
      irThreshold2x: 100;
      irThreshold3x: 100;
      rssi: [[1625314065, -49]];
      battery: [];
      dbm: 0;
      vmUse: 283012;
      resSet: 15924;
      uptime: 2589836.68;
      wifiDisconnects: 1;
      wifiDriverReloads: 1;
      statsPeriod: 600000;
      sarlaccDebugLogTypes: 0;
      odProcessingFps: 8;
      odObjectMinWidthPercent: 3;
      odObjectMinHeightPercent: 12;
      odEnableObjectDetection: true;
      odClassificationMask: 2;
      odClassificationConfidenceThreshold: 0.9;
      odEnableOverlay: false;
      odAnalyticsLib: 2;
      odSensitivity: 95;
      odEventObjectMask: 2;
      odLuxThreshold: 840;
      odLuxHysteresisHigh: 10;
      odLuxHysteresisLow: 2;
      odLuxSamplingFrequency: 30;
      odFGExtractorMode: 2;
      odVideoScaleFactor: 1;
      odSceneType: 1;
      odCameraView: 3;
      odCameraFOV: 2;
      odBackgroundLearnStationary: true;
      odBackgroundLearnStationarySpeed: 15;
      odClassifierQualityProfile: 1;
      odEnableVideoAnalyticsWhileStreaming: false;
      wlanMac: '94:a1:a2:59:f7:3d';
      region: 'us-east-1';
      enableWifiAnalyticsLib: false;
      ivLicense: string;
    };
    pirLevel: 'high';
    odLevel: 'high';
  };
  uid: 154;
  cameraStatus: {
    firmwareVersion: '2.5.2.76';
    netConfigVersion: string;
    camAgentVersion: string;
    lastLogin: 1623876890;
    lastLogout: 1623876889;
    wlanMac: '94:a1:a2:59:f7:3d';
    fwDownloadVersion: string;
    fwDownloadPercentage: 0;
    recovered: false;
    recoveredFromVersion: string;
    batteryPercentage: 0;
    _id: '5edd7ebbb04c0c0ac6674380';
    initErrors: [];
    speedTestTokenCreated: 1612720927;
  };
  supportedFeatures: {
    providers: {
      webrtc: 'none';
      recording: 'simplisafe';
      live: 'simplisafe';
    };
    audioEncodings: ['speex'];
    resolutions: ['480p', '720p'];
    _id: '604bbbf361a9b10ad889e381';
    pir: true;
    videoAnalytics: false;
    privacyShutter: true;
    microphone: true;
    fullDuplexAudio: false;
    wired: true;
    battery: false;
    networkSpeedTest: false;
    speaker: true;
    spotlight: false;
    colorNightMode: false;
    videoFlip: false;
    doorbell: false;
    videoAnalyticsZones: false;
    pairedToBasestation: false;
    siren: false;
    videoEncoding: 'h264';
    aspectRatio: string;
    spotlightManualControl: boolean;
  };
  subscription: {
    enabled: true;
    freeTrialActive: false;
    freeTrialUsed: true;
    freeTrialEnds: 0;
    freeTrialExpires: 0;
    planSku: 'SSVM1';
    price: 0;
    expires: 0;
    storageDays: 30;
    trialUsed: true;
    trialActive: false;
    trialExpires: 0;
  };
  status: 'online';
  currentState: {
    idle: false;
    liveStreaming: false;
    recording: false;
    otaDownloading: false;
    otaFlashing: false;
    batteryCharging: false;
    ts: null;
  };
};

export type SsSubscriptionsObject = {
  subscriptions: SsSubscription[];
};
export type SsSubscription = {
  uid: number;
  sid: number;
  sStatus: number;
  activated: number;
  planSku: string;
  planName: string;
  price: number;
  currency: string;
  country: string;
  expires: number;
  canceled: number;
  extraTime: number;
  time: number;
  features: {
    monitoring: boolean;
    alerts: boolean;
    online: boolean;
    hazard: boolean;
    video: boolean;
    cameras: number;
    dispatch: boolean;
    proInstall: boolean;
    discount: number;
    vipCS: boolean;
    medical: boolean;
    careVisit: boolean;
    storageDays: number;
  };
  status: {
    hasBaseStation: boolean;
    isActive: boolean;
    monitoring: string; // 'Active'
  };
  location: {
    lStatus: number;
    account: string;
    street1: string;
    street2: string;
    locationName: '17 Hawes St (SS3)';
    city: 'Brookline';
    county: 'NORFOLK';
    state: 'MA';
    zip: '02446';
    country: 'US';
    crossStreet: 'Beech';
    notes: 'Brick house at the end of Beech St on Hawes St, next to BU school building';
    residenceType: number;
    numAdults: 1;
    numChildren: 2;
    locationOffset: -240;
    safeWord: 'SOHO';
    signature: 'CL';
    timeZone: 0;
    primaryContacts: [
      {
        name: string;
        firstName: string;
        lastName: string;
        phone: string;
      },
      {
        name: string;
        firstName: string;
        lastName: string;
        phone: string;
      }
    ];
    secondaryContacts: [
      {
        name: string;
        phone: string;
      },
      {
        name: string;
        phone: string;
      },
      {
        name: string;
        phone: string;
      },
      {
        name: string;
        phone: string;
      },
      {
        name: string;
        phone: string;
      }
    ];
    copsOptIn: true;
    system: {
      serial: string;
      alarmState: 'OFF' | 'AWAY' | 'HOME' | string; //other states like exit delay
      alarmStateTimestamp: number;
      isAlarming: boolean;
      version: number;
      temperature: number; //degrees
      exitDelayRemaining: number;
      cameras: SsCamera[];
      connType: 'wifi';
      stateUpdated: 1625323785;
      messages: [];
      powerOutage: false;
      lastPowerOutage: 1624190710;
      lastSuccessfulWifiTS: 1625323785;
      isOffline: false;
    };
  };
};
