import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import Route from 'src/route';
import { usePcsAuthRep } from 'src/context/pcs-auth-context';
import unAuthRoutes from 'src/routes-no-auth';
import FullPageSpinner from 'src/components/FullPageSpinner';
import VersionCheck from 'src/components/VersionCheck';
import ScreenContextProvider from './context/ScreenRecordingContext';
import SearchEventContextProvider from './views/events/SearchEventContext';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import {
  BatchSpanProcessor,
  ConsoleSpanExporter,
  SimpleSpanProcessor,
  WebTracerProvider,
} from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { ATTR_SERVICE_NAME } from '@opentelemetry/semantic-conventions';
import { Resource } from '@opentelemetry/resources';

// Set up OTEL tracing
const collectorOptions = {
  url: process.env.REACT_APP_OTEL_TRACE_COLLECTOR_URL,
  concurrencyLimit: 10, // an optional limit on pending requests
};

const exporter = new OTLPTraceExporter(collectorOptions);
const provider = new WebTracerProvider({
  resource: new Resource({ [ATTR_SERVICE_NAME]: 'pcs-agent-portal' }),
  spanProcessors: [
    new SimpleSpanProcessor(new ConsoleSpanExporter()),
    new BatchSpanProcessor(exporter, {
      // The maximum queue size. After the size is reached spans are dropped.
      maxQueueSize: 100,
      // The maximum batch size of every export. It must be smaller or equal to maxQueueSize.
      maxExportBatchSize: 10,
      // The interval between two consecutive exports
      scheduledDelayMillis: 500,
      // How long the export can run before it is cancelled
      exportTimeoutMillis: 30000,
    }),
  ],
});

provider.register();

registerInstrumentations({
  instrumentations: [
    getWebAutoInstrumentations({
      '@opentelemetry/instrumentation-xml-http-request': {
        clearTimingResources: true,
      },
    }),
  ],
});

const App = () => {
  const { id } = usePcsAuthRep();
  const unAuthRouting = useRoutes(unAuthRoutes);

  return (
    <>
      <React.Suspense fallback={<FullPageSpinner message="Loading App..." />}>
        <ScreenContextProvider>
          <SearchEventContextProvider>
            {id ? <Route /> : unAuthRouting}
          </SearchEventContextProvider>
        </ScreenContextProvider>
        <VersionCheck />
      </React.Suspense>
    </>
  );
};

export default App;
