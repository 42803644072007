import React from 'react';
import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Typography,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1),
    // marginLeft: theme.spacing(-1),
    // marginRight: theme.spacing(-1)
  },
}));

export default function DenseCardHeader(props) {
  const {
    title,
    second = '',
    close = false,
    onClose,
    onSecond,
    handleClick,
    cameraIcon,
    tooltipData,
    subTitle,
    style,
  } = props;

  const classes = useStyles();
  return (
    <>
      <Box display="flex" style={{ width: '100%', ...style }}>
        <Box display={'flex'} gridGap={5}>
          <Typography
            variant="overline"
            fontSize="inherit"
            style={{ marginTop: 5 }}
          >
            {title}
          </Typography>
          {cameraIcon && (
            <Tooltip title={tooltipData} placement="top">
              <img
                src={`/static/images/${cameraIcon}.svg`}
                width="20"
                height="20"
              ></img>
            </Tooltip>
          )}
        </Box>
        {second !== '' && (
          <Box style={{ flex: 1, marginTop: 3 }}>
            <IconButton
              onClick={onSecond}
              className={classes.margin}
              aria-label="close"
              size="small"
            >
              <Typography variant="overline" fontSize="inherit">
                {second}
              </Typography>
            </IconButton>
          </Box>
        )}
        {subTitle}
        {close && (
          <Box style={{ flex: 1, textAlign: 'right' }}>
            <IconButton
              onClick={handleClick}
              className={classes.margin}
              aria-label="close"
              size="small"
            >
              <CloseIcon fontSize="inherit" />
              {/* <Typography variant="overline" fontSize="inherit">
                X
              </Typography> */}
            </IconButton>
          </Box>
        )}
      </Box>
      <Divider />
    </>
  );

  // Old Grid version
  // return (
  //   <>
  //     <Grid container direction="row" justifyContent="space-between">
  //       <Grid item xs={9}>
  //         <CardHeader
  //           title={title}
  //           titleTypographyProps={{
  //             variant: 'overline'
  //             // Set in theme instead
  //             // style: { lineHeight: '1.5' }
  //           }}
  //           style={{
  //             padding: '2px 8px'
  //           }}
  //         />
  //       </Grid>
  //       {second !== '' && (
  //         <Grid item xs={1}>
  //           <IconButton
  //             className={classes.margin}
  //             aria-label="close"
  //             size="small"
  //           >
  //             <Typography variant="overline" fontSize="inherit">
  //               {second}
  //             </Typography>
  //           </IconButton>
  //         </Grid>
  //       )}
  //       {close && (
  //         <Grid item xs={1}>
  //           <IconButton
  //             onClick={onClose}
  //             className={classes.margin}
  //             aria-label="close"
  //             size="small"
  //           >
  //             <CloseIcon fontSize="inherit" />
  //           </IconButton>
  //         </Grid>
  //       )}
  //     </Grid>
  //     <Divider />
  //   </>
  // );
}

DenseCardHeader.propTypes = {
  title: PropTypes.string,
  second: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  close: PropTypes.bool,
  onClose: PropTypes.func,
  onSecond: PropTypes.func,
  cameraIcon: PropTypes.string,
  tooltipData: PropTypes.string,
};
