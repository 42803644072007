import {
  createOpenMetricDispatch,
  MetricsContext,
} from 'src/context/Metrics-context';
import { INSTRUMENTS } from './metricHooks';
import { useContext, useEffect } from 'react';

export enum WorkStateAction {
  ACCEPT_NEW = 'accept_new',
  HANDLE_EVENT_VERIFY_AND_RESPONSE = 'handle_event_verify_and_response',
  HANDLE_EVENT_FOLLOW_UP = 'handle_event_follow_up',
}

export const AGENT_ENTERED_WORK_STATE_AT = 'agentEnteredWorkStateAt';

const useAgentWorkState = () => {
  const isInWorkState =
    localStorage.getItem(AGENT_ENTERED_WORK_STATE_AT) != null;

  const [_, dispatch] = useContext(MetricsContext);
  const openMetricDispatch = createOpenMetricDispatch(dispatch);

  const setEnteredWorkState = (actionName: WorkStateAction) => {
    if (!isInWorkState) {
      // Milestone to Kafka
      openMetricDispatch({
        metricName: INSTRUMENTS.AGENT_ENTERED_WORK_STATE,
        payload: {
          actionName: actionName,
        },
      });
      localStorage.setItem(
        AGENT_ENTERED_WORK_STATE_AT,
        new Date().toISOString()
      );
    }
  };

  const setExitedWorkState = () => {
    localStorage.removeItem(AGENT_ENTERED_WORK_STATE_AT);
  };

  // Triggers the exited work state when the user logs out, closes the tab, or refreshes the page.
  // This handles all cases where the user leaves the page, ensuring the work state is properly exited.
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setExitedWorkState();
      }
    };

    window.addEventListener('beforeunload', setExitedWorkState);
    window.addEventListener('unload', setExitedWorkState);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('beforeunload', setExitedWorkState);
      window.removeEventListener('unload', setExitedWorkState);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return {
    setEnteredWorkState,
    setExitedWorkState,
  };
};

export default useAgentWorkState;
