import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import DispatchModal from './DispatchModal';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { useAlarmState, useSetAlarmState } from 'src/hooks/useAlarmState';
import { SsSubscription } from 'src/types';
import { useGetRepMe } from 'src/hooks/repHooks';
import { Role } from 'src/models';

// STATUS ITEMS - alarm/siren, home/away/off, doorlock, permit info?

const ALARM_STATES = {
  OFF: 'OFF',
  HOME: 'HOME',
  HOME_COUNT: 'HOME_COUNT',
  AWAY: 'AWAY',
  AWAY_COUNT: 'AWAY_COUNT',
  HOME2: 'HOME2',
  HOME2_COUNT: 'HOME2_COUNT',
  AWAY2: 'AWAY2',
  AWAY2_COUNT: 'AWAY2_COUNT',
  ALARM: 'ALARM',
  ALARM_COUNT: 'ALARM_COUNT',
  MENU: 'MENU',
  TEST: 'TEST',
  NAMING: 'NAMING',
  INSTALL: 'INSTALL',
  UPDATE: 'UPDATE',
  DOWNLOAD_BS: 'DOWNLOAD_BS',
  DOWNLOAD_KP: 'DOWNLOAD_KP',
  DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
  DOWNLOAD_FAIL: 'DOWNLOAD_FAIL',
};

type Props = {
  subscription: SsSubscription;
  dispatch: any;
  payload: any;
  sendMetrics: any;
  fakeAlarm: null | string;
  eventId: number | null;
  event: any;
};

export const AlarmStatus = ({
  subscription,
  dispatch,
  payload,
  sendMetrics,
  fakeAlarm,
  eventId,
  event
}: Props) => {
  const repQuery = useGetRepMe();
  const isNotAdmin = repQuery.data?.role !== Role.ADMIN;

  const powerOutage =
    subscription?.location?.system?.powerOutage?.toString() || 'Not informed';
  // Locks - currently not used
  // const lockArray = [];
  // locks.forEach(lock => {
  //   lockArray = [
  //     ...lockArray,
  //     { serial: lock.serial, name: lock.name, status: !!lock.status.lockState }
  //   ];
  // });
  // const [lockState, setLockState] = useState(lockArray);

  const handleSetState = async (e) => {
    await setAlarmMutation.mutate(e.currentTarget.value); // value is null if you click the currently selected button... use currentTarget
    dispatch({
      type: 'SEND',
      payload: { ...payload.current, metricName: 'AlarmStatus-change' },
    });
    sendMetrics.current = false;
  };

  const alarmStateQuery = useAlarmState({
    sid: subscription?.sid,
    account: subscription?.location.account,
    userId: subscription?.uid,
  });

  const setAlarmMutation = useSetAlarmState({
    sid: subscription?.sid,
    account: subscription?.location.account,
    userId: subscription?.uid,
  });

  const alarmStatusGroupButton = (alarmStatus: string) => (
    <ToggleButtonGroup
      size="small"
      color="primary"
      // value={subscription?.location.system.alarmState}
      value={alarmStatus === 'AWAY_COUNT' ? 'AWAY' : alarmStatus || 'UNKNOWN'}
      exclusive
      onChange={handleSetState}
    >
      <ToggleButton
        style={alarmStatus === ALARM_STATES.OFF ? { color: 'white' } : {}}
        disabled={isNotAdmin}
        value={ALARM_STATES.OFF}
      >
        Off
      </ToggleButton>
      <ToggleButton
        style={alarmStatus === ALARM_STATES.HOME ? { color: 'white' } : {}}
        disabled={isNotAdmin}
        value={ALARM_STATES.HOME}
      >
        Home
      </ToggleButton>
      <ToggleButton
        style={alarmStatus === 'AWAY_COUNT' ? { color: 'white' } : {}}
        disabled={isNotAdmin}
        value={ALARM_STATES.AWAY}
      >
        Away
      </ToggleButton>
      <ToggleButton
        value={ALARM_STATES.ALARM}
        style={
          // subscription?.location.system.alarmState === ALARM_STATES.ALARM
          alarmStatus === ALARM_STATES.ALARM ? { color: 'red' } : null
        }
      >
        <VolumeUpIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  if (!subscription && !fakeAlarm)
    return <LoadingSpinner message="Loading subscription from Yoda" />;

  if (!subscription?.sid && !fakeAlarm) return <LoadingSpinner />;
  if (alarmStateQuery.isLoading || setAlarmMutation.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      {alarmStatusGroupButton(alarmStateQuery?.data?.state || fakeAlarm)}
      {/* <ToggleButton
        value="check"
        size="small"
        // variant="contained"
        selected={sirenState}
        onChange={() => {
          setSirenState(!sirenState);
        }}
      >
        <VolumeUpIcon />
      </ToggleButton> */}
      <Typography variant="overline" style={{ display: 'inline-flex' }}>
        Power outage: {powerOutage}
      </Typography>
      <DispatchModal eventId={eventId} userId={subscription?.uid} event={event} />
      {/* <ToggleButton
        value="check"
        size="small"
        variant="contained"
        selected={lockState[0].status}
        onChange={() => {
          setLockState(!lockState[0].status);
        }}
      >
        <LockIcon />
        {lockState[0].name}
      </ToggleButton> */}
    </Box>
  );
};
